import React, { memo, useEffect, useState, useContext, useRef } from "react";
import Dragula from "react-dragula";
import "react-dragula/dist/dragula.min.css";
import ListMonthCard from "../ListMonthCard/index";
import {
  updateAppointment,
  checkNotification,
  completeAppoinment,
} from "shared";
import moment from "moment/moment";
import "./index.css";
import { RescheduleModal } from "../../Appointment/RescheduleModal";
import { useDispatch } from "react-redux";
import { CurrentUser } from "../../../../context/UserContext";
import NEWReceptionFormModal from "../../Modals/Appointments/NEWReceptionFormModal";
import { fetchAllCoworkers } from "shared/src/services/userAPI";
import DiagnosticFormModal from "../../Modals/Appointments/DiagnosticFormModal";
import {
  CalendarDeleteIcon,
  LeftChevronIcon,
} from "../../../../assets/SvgIcons";
import { ConfirmationModal } from "../../Modals/ConfirmationModal";
import { useWindowSize } from "../../../Utilities";
import ConfirmAppointmentModal from "../../Modals/Appointments/ConfirmAppointmentModal";
import { AppointmentAPI } from "../../../Services/axios";
import { CkButton, CkMessage } from "../../../../CkUI";

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

interface IProps {
  list: any[];
  SimulateEventClick: Function;
  hidden: boolean;
  hours: any[];
  workshopId: string | null;
  setRefresh: Function;
}

const AppoGridView: React.FC<IProps> = ({
  list,
  SimulateEventClick,
  hidden,
  hours,
  workshopId,
  setRefresh,
}) => {
  const windowSize = useWindowSize();
  const horizontalScrollRef = React.useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const dispatch = useDispatch<any>();
  const user = useContext(CurrentUser);
  const [orderedList, setOrderedList] = useState<any[][]>([]);
  const [loadingNewState, setLoadingNewState] = useState<boolean>(false);
  const [currentAppointment, setCurrentAppointment] = useState<any>({});
  const [showAppointmentApprovalModal, setShowAppointmentApprovalModal] =
    useState<boolean>(false);
  // Drag and drop refs
  const PENDdragRef = useRef(null);
  const APPOdragRef = useRef(null);
  const RECEPdragRef = useRef(null);
  const QUOTdragRef = useRef(null);
  const PROGdragRef = useRef(null);
  const READYdragRef = useRef(null);
  const CLOSdragRef = useRef(null);

  // RescheduleModal
  const [showNewAppointmentDate, setShowNewAppointmentDate] =
    useState<boolean>(false);
  const [loadingReschedule, setLoadingReschedule] = useState<boolean>(false);
  const [newAppointmentDate, setNewAppointmentDate] = useState<any>({
    date: moment(new Date()),
    time: "",
  });
  const [appointmentHourList, setAppointmentHourList] = useState<string[]>([]);
  // Reception Form
  const [showReceptionForm, setShowReceptionForm] = useState<boolean>(false);
  const [coworkers, setCoworkers] = useState<any>([]);
  // Diagnostic/Quotation Form
  const [showDiagnosticForm, setShowDiagnosticForm] = useState<boolean>(false);
  const [diagnosticFormMode, setDiagnosticFormMode] = useState<"QUOT" | "DIAG">(
    "QUOT",
  );
  // Cancel
  const [showCancelAppointment, setShowCancelAppointment] =
    useState<boolean>(false);

  const actionList: any = {
    CREAT: {
      label: "Confirmó por otro medio",
      func: (data: any) => {
        setLoadingNewState(true);
        setCurrentAppointment(data);
        setShowAppointmentApprovalModal(true);
      },
    },
    PEND: {
      label: "Confirmar cita",
      func: async (data: any) => {
        setLoadingNewState(true);
        setCurrentAppointment(data);
        updateAppointmentStatus(data, "APPO");
      },
    },
    APPO: {
      label: "Recibir vehículo",
      func: (data: any) => {
        setLoadingNewState(true);
        setCurrentAppointment(data);
        setShowReceptionForm(true);
      },
    },
    RECEP: {
      label: "Diagnosticar vehículo",
      func: (data: any) => {
        setLoadingNewState(true);
        setCurrentAppointment(data);
        setDiagnosticFormMode("DIAG");
        setShowDiagnosticForm(true);
      },
    },
    DIAG: {
      label: "",
      func: () => {},
    },
    QUOT: {
      notApproved: {
        label: "Editar cotización",
        func: (data: any) => {
          setLoadingNewState(true);
          setCurrentAppointment(data);
          setDiagnosticFormMode("QUOT");
          setShowDiagnosticForm(true);
        },
      },
      approved: {
        label: "Iniciar reparación",
        func: (data: any) => {
          setLoadingNewState(true);
          setCurrentAppointment(data);
          handleConfirm(data, "PROG");
        },
      },
    },
    PROG: {
      label: "Terminar reparación",
      func: (data: any) => {
        setLoadingNewState(true);
        setCurrentAppointment(data);
        handleConfirm(data, "READY");
      },
    },
    READY: {
      label: "Entregar vehículo",
      func: (data: any) => {
        setLoadingNewState(true);
        setCurrentAppointment(data);
        handleConfirm(data, "CLOS");
      },
    },
    CLOS: {},
    CANCE: {
      label: "Reagendar cita",
      func: (data: any) => {
        setLoadingNewState(true);
        setCurrentAppointment(data);
        setShowNewAppointmentDate(true);
      },
    },
  };

  const organizeList = (events: any[]) => {
    const listTemp: any[][] = [[], [], [], [], [], [], [], []];
    events.forEach((event: any) => {
      switch (event.statusCode) {
        case "CREAT":
          listTemp[0].push(event);
          break;
        case "PEND":
          listTemp[0].push(event);
          break;
        case "APPO":
          listTemp[1].push(event);
          break;
        case "RECEP":
          listTemp[2].push(event);
          break;
        case "QUOT":
          listTemp[3].push(event);
          break;
        case "PROG":
          listTemp[4].push(event);
          break;
        case "READY":
          listTemp[5].push(event);
          break;
        case "CLOS":
          listTemp[6].push(event);
          break;
        case "CANCE":
          listTemp[7].push(event);
          break;
        default:
          console.warn("unknown status code");
          break;
      }
    });
    setOrderedList(listTemp);
  };

  useEffect(() => {
    organizeList(list);
  }, [list]);

  useEffect(() => {
    if (windowSize && horizontalScrollRef.current) {
      horizontalScrollRef.current.scrollLeft = 0;
      if (horizontalScrollRef.current.clientWidth > 1717)
        setShowRightArrow(false);
      else setShowRightArrow(true);
    }
  }, [windowSize, horizontalScrollRef]);

  const getClosedDays = () => {
    let closedDaysArray: number[] = [];
    const closedDays = hours.filter((day: any) => day.isOpen === false);

    closedDays?.forEach((day) => {
      closedDaysArray.push(day.dayCode === 7 ? 0 : day.dayCode);
    });
    return closedDaysArray;
  };

  const getMonthByNumber = (index: number) => {
    return months[index];
  };

  const getWorkingHoursByDay = (momentIndex: number) => {
    let timeList = [];
    const index = momentIndex === 0 ? 7 : momentIndex;
    const workDay = hours.find((day) => index === day.dayCode);
    if (workDay?.isOpen) {
      const initialTime = moment(workDay?.openTime, "HH:mmZZ");
      const closingTime = moment(workDay?.closeTime, "HH:mmZZ");
      if (closingTime < initialTime) {
        closingTime.add(1, "d");
      }
      const hourAmmount = moment
        .duration(closingTime.diff(initialTime))
        .asHours();

      let time = initialTime;
      let timeValues = [time.hour(), time.minutes()];
      for (let i = 0; i < hourAmmount; i++) {
        if (timeValues[1] === 0) {
          timeList.push(
            `${timeValues[0] < 10 ? "0" + timeValues[0] : timeValues[0]}:${
              "0" + timeValues[1]
            }`,
          );
          time.add(30, "m");
          timeValues = [time.hour(), time.minutes()];
        }
        if (timeValues[1] === 30) {
          timeList.push(
            `${timeValues[0] < 10 ? "0" + timeValues[0] : timeValues[0]}:${
              timeValues[1]
            }`,
          );
          time.add(30, "m");
          timeValues = [time.hour(), time.minutes()];
        }
      }
    }
    setAppointmentHourList(timeList);
  };

  useEffect(() => {
    const getCoworkers = async () => {
      if (workshopId && workshopId !== "") {
        const result = await fetchAllCoworkers(workshopId);
        if (result.status === 200) setCoworkers([...result.data]);
      }
    };

    if (currentAppointment) {
      getCoworkers();
      const dateMoment = moment(new Date(currentAppointment?.startDateTime));

      getWorkingHoursByDay(dateMoment.day());
      setNewAppointmentDate({ date: dateMoment, time: "" });
    }
  }, [currentAppointment]);

  const exitModal = () => {
    setShowNewAppointmentDate(false);
    setShowReceptionForm(false);
    setShowCancelAppointment(false);
    setShowDiagnosticForm(false);
    setLoadingReschedule(false);
    setLoadingNewState(false);
    setRefresh(true);
  };

  useEffect(() => {
    if (!showNewAppointmentDate && !showReceptionForm && !showDiagnosticForm) {
      setLoadingReschedule(false);
      setLoadingNewState(false);
    }
  }, [showNewAppointmentDate, showReceptionForm, showDiagnosticForm]);

  const rescheduleAppointment = async () => {
    const servicesInfo = currentAppointment?.serviceTypes;
    let services = [];
    if (currentAppointment?.packages.length === 0) {
      services = servicesInfo.map((service: any) => {
        return { serviceTypeCode: service.serviceTypeCode };
      });
    }
    const appoinmentDuration = moment(currentAppointment?.endDateTime).diff(
      moment(currentAppointment?.startDateTime),
      "minutes",
    );
    let date: moment.Moment = newAppointmentDate.date!;
    let time = moment(newAppointmentDate.time, "HH:mmZ");

    if (time.utcOffset() / 60 < 0 && time.hour() + time.utcOffset() / 60 < 0) {
      date = date.subtract(1, "d");
    } else if (
      time.utcOffset() / 60 > 0 &&
      time.hour() + time.utcOffset() / 60 > 24
    ) {
      date = date.add(1, "d");
    }
    date.hour(time.hour());
    date.minute(time.minute());

    const appointment: any = {
      ...currentAppointment,
      id: currentAppointment?.id,
      startDateTime: date.toISOString(),
      endDateTime: date.add(appoinmentDuration, "m").toISOString(),
      statusCode: "CREAT",
      serviceTypes: services,
    };

    const loadingMessageKey = CkMessage({type: "loading", text: "Actualizando cita..."});

    setLoadingReschedule(true);
    dispatch(
      updateAppointment({
        appointment: appointment,
        workshopId: workshopId,
      }),
    )
      .then((response: any) => {
        CkMessage({type: "success", text: "Cita actualizada", messageToDestroy: loadingMessageKey});
        if (response) {
          exitModal();
        }
      })
      .catch(() => {
        CkMessage({type: "error", text: "Ocurrió un error al actualizar la cita", messageToDestroy: loadingMessageKey});
        exitModal();
      });
  };

  async function handleConfirmOld(
    state: string | null = null,
    LicensePlate?: string | undefined,
    km: string | null = null,
  ) {
    try {
      const values = currentAppointment;

      let services;
      if (currentAppointment?.packages.length === 0) {
        services = values.serviceTypes.map((service: any) => {
          return { serviceTypeCode: service?.serviceTypeCode };
        });
      }

      const isCustomBrand =
        currentAppointment?.vehicle?.brandCode?.startsWith("custom_brand");
      const isCustomModel =
        currentAppointment?.vehicle?.modelCode?.startsWith("custom_model");

      const vehicleInfo: {
        Nickname: string;
        TypeCode: string;
        TypeName: string;
        BrandCode: string;
        BrandName: string;
        IsCustomBrand: boolean;
        YearCode: string;
        Year: string;
        ModelCode: string;
        ModelName: string;
        FuelTypeCode: string;
        FuelTypeName: string;
        IsCustomModel: boolean;
        IsEnabled: boolean;
        Km: string | number;
        LicensePlate: string;
        VehicleId: number | "" | null;
      } = {
        Nickname: `${values.vehicle.brandCode.toUpperCase()} - ${values.vehicle.modelCode.toUpperCase()}`,
        TypeCode: values.vehicle.typeCode,
        TypeName: values.vehicle.typeCode,
        BrandCode: values.vehicle.brandCode,
        BrandName: values.vehicle.brandCode,
        IsCustomBrand: isCustomBrand,
        YearCode: values.vehicle.yearCode,
        Year: values.vehicle.yearCode,
        ModelCode: values.vehicle.modelCode,
        ModelName: values.vehicle.modelCode,
        FuelTypeCode: values.vehicle.fuelTypeCode,
        FuelTypeName: values.vehicle.fuelTypeCode,
        IsCustomModel: isCustomBrand ? true : isCustomModel,
        IsEnabled: true,
        Km: state === "RECEP"? km.toString() : values.vehicle.km.toString(),
        LicensePlate: LicensePlate ? LicensePlate : values.vehicle.licensePlate,
        VehicleId: currentAppointment?.vehicle.vehicleId,
      };

      const appointmentStatus = currentAppointment?.statusCode;
      const toAdd: any = {
        id: currentAppointment?.id as string,
        statusCode:
          state !== undefined && state !== null && state !== "CLOSTEMP"
            ? state
            : appointmentStatus === "CLOSTEMP"
              ? "CLOS"
              : appointmentStatus,
        startDateTime: values.startDateTime,
        endDateTime: values.endDateTime,
        detail: "",
        workshopUserId: user?.id as string,
        endConsumer: {
          name: values.endConsumer.name as string,
          lastName: values.endConsumer.lastName,
          phone: values.endConsumer.phone as string,
          email: values.endConsumer.email as string,
        },
        vehicle: vehicleInfo,
      };

      if (currentAppointment?.packages.length > 0) {
        toAdd.packages = currentAppointment?.packages;
        toAdd.serviceTypes = [];
      } else {
        toAdd.serviceTypes = services;
      }

      const loadingMessageKey = CkMessage({type: "loading", text: "Guardando datos..."});
      dispatch(
        updateAppointment({ appointment: toAdd, workshopId: workshopId }),
      )
        .then((result: any) => {
          if (result.payload?.data?.statusCode === "CLOS") {
            dispatch(
              completeAppoinment(result.payload.data.endConsumer.email),
            ).then(() => {
              dispatch(
                checkNotification(result.payload.data.endConsumer.email),
              );
            });
          }
          CkMessage({ type: "success", text: "Cita actualizada", messageToDestroy: loadingMessageKey})
          exitModal();
        })
        .catch(() => {
          CkMessage({type: "error", text: "Ocurrió un error al actualizar la cita", messageToDestroy: loadingMessageKey});
          exitModal();
        });

      exitModal();
    } catch (errorInfo) {}
  }

  async function handleConfirm(
    data: any,
    state: string | null = null,
    LicensePlate?: string | undefined,
  ) {
    try {
      const values = data;

      let services;
      if (data?.packages.length === 0) {
        services = values.serviceTypes.map((service: any) => {
          return { serviceTypeCode: service?.serviceTypeCode };
        });
      }

      const isCustomBrand =
        data?.vehicle?.brandCode?.startsWith("custom_brand");
      const isCustomModel =
        data?.vehicle?.modelCode?.startsWith("custom_model");

      const vehicleInfo: {
        Nickname: string;
        TypeCode: string;
        TypeName: string;
        BrandCode: string;
        BrandName: string;
        IsCustomBrand: boolean;
        YearCode: string;
        Year: string;
        ModelCode: string;
        ModelName: string;
        FuelTypeCode: string;
        FuelTypeName: string;
        IsCustomModel: boolean;
        IsEnabled: boolean;
        Km: string | number;
        LicensePlate: string;
        VehicleId: number | "" | null;
      } = {
        Nickname: `${values.vehicle.brandCode.toUpperCase()} - ${values.vehicle.modelCode.toUpperCase()}`,
        TypeCode: values.vehicle.typeCode,
        TypeName: values.vehicle.typeCode,
        BrandCode: values.vehicle.brandCode,
        BrandName: values.vehicle.brandCode,
        IsCustomBrand: isCustomBrand,
        YearCode: values.vehicle.yearCode,
        Year: values.vehicle.yearCode,
        ModelCode: values.vehicle.modelCode,
        ModelName: values.vehicle.modelCode,
        FuelTypeCode: values.vehicle.fuelTypeCode,
        FuelTypeName: values.vehicle.fuelTypeCode,
        IsCustomModel: isCustomBrand ? true : isCustomModel,
        IsEnabled: true,
        Km: values.vehicle.km.toString(),
        LicensePlate: LicensePlate ? LicensePlate : values.vehicle.licensePlate,
        VehicleId: data?.vehicle.vehicleId,
      };

      const appointmentStatus = data?.statusCode;
      const toAdd: any = {
        id: data?.id as string,
        statusCode:
          state !== undefined && state !== null && state !== "CLOSTEMP"
            ? state
            : appointmentStatus === "CLOSTEMP"
              ? "CLOS"
              : appointmentStatus,
        startDateTime: values.startDateTime,
        endDateTime: values.endDateTime,
        detail: "",
        workshopUserId: user?.id as string,
        endConsumer: {
          name: values.endConsumer.name as string,
          lastName: values.endConsumer.lastName,
          phone: values.endConsumer.phone as string,
          email: values.endConsumer.email as string,
        },
        vehicle: vehicleInfo,
      };

      if (data?.packages.length > 0) {
        toAdd.packages = data?.packages;
        toAdd.serviceTypes = [];
      } else {
        toAdd.serviceTypes = services;
      }

      const loadingMessageKey = CkMessage({type: "loading", text: "Guardando datos..."});
      dispatch(
        updateAppointment({ appointment: toAdd, workshopId: workshopId }),
      )
        .then((result: any) => {
          if (result.payload?.data?.statusCode === "CLOS") {
            dispatch(
              completeAppoinment(result.payload.data.endConsumer.email),
            ).then(() => {
              exitModal();
              dispatch(
                checkNotification(result.payload.data.endConsumer.email),
              );
            });
          }
          CkMessage({ type: "success", text: "Cita actualizada", messageToDestroy: loadingMessageKey})
        })
        .catch(() => {
          CkMessage({type: "error", text: "Ocurrió un error al actualizar la cita", messageToDestroy: loadingMessageKey});
          exitModal();
        });

      exitModal();
    } catch (errorInfo) {}
  }

  const confirmAppointment = (
    newStatus: string,
    comment: string | undefined = "",
  ) => {
    setShowAppointmentApprovalModal(false);
    const validationCode = currentAppointment?.lastValidationCode;

    if (newStatus !== "APPROVED" || comment === "" || !validationCode) {
      CkMessage({type: "error", text: "Ups, ocurrio un problema al confirmar la cita."});
      return;
    }

    const loadingMessageKey = CkMessage({type: "loading", text: "Confirmando cita."});
    AppointmentAPI.confirm(currentAppointment.id, validationCode, comment)
      .then((response: any) => {
        if (response.data) {
          CkMessage({type: "success", text: "La cita fue confirmada.", messageToDestroy: loadingMessageKey });
        } else {
          CkMessage({
            type: "error",
            text: "Ups, ocurrio un problema al confirmar la cita.",
            messageToDestroy: loadingMessageKey
          });
        }
        exitModal();
      })
      .catch(() => {
        CkMessage({type: "error", text: "Ups, ocurrio un problema al confirmar la cita.", messageToDestroy: loadingMessageKey});
        exitModal();
      });
  };

  const updateAppointmentStatus = async (data: any, status: string) => {
    let services = data?.serviceTypes;
    let packages = data?.packages;

    if (packages.length === 0) {
      services = services.map((service: any) => {
        return { serviceTypeCode: service.serviceTypeCode };
      });
    }

    const loadingMessageKey = CkMessage({type: "loading", text: "Actualizando cita..."});
    let appointment: any = {
      ...data,
      id: data?.id,
      statusCode: status,
      detail: "",
    };

    if (packages.length > 0) {
      const packageList = [
        {
          WorkshopServicePackageSetId: packages[0].workshopServicePackageSetId,
          WorkshopId: workshopId,
          Price: packages[0].price,
        },
      ];
      appointment.packages = packageList;
      appointment.serviceTypes = [];
    } else {
      appointment.serviceTypes = services;
    }

    dispatch(
      updateAppointment({
        appointment: appointment,
        workshopId: workshopId,
      }),
    )
      .then(() => {
        CkMessage({ type: "success", text: "Cita actualizada", messageToDestroy: loadingMessageKey});
        exitModal();
      })
      .catch(() => {
        CkMessage({type: "error", text: "Ocurrió un error al actualizar la cita"});
        exitModal();
      });
  };

  const findAppointment = (id: string, status: string, list: any) => {
    if (status === "PEND") {
      return list[0].find((appo: any) => appo.id === id);
    } else if (status === "CREAT") {
      return list[0].find((appo: any) => appo.id === id);
    } else if (status === "APPO") {
      return list[1].find((appo: any) => appo.id === id);
    } else if (status === "RECEP") {
      return list[2].find((appo: any) => appo.id === id);
    } else if (status === "QUOT") {
      return list[3].find((appo: any) => appo.id === id);
    } else if (status === "PROG") {
      return list[4].find((appo: any) => appo.id === id);
    } else if (status === "READY") {
      return list[5].find((appo: any) => appo.id === id);
    }
    CkMessage({type: "error", text: "Ocurrió un error al actualizar la cita."});
  };

  // Drag and Drop states
  useEffect(() => {
    if (!loadingNewState) {
      const pendContainer = PENDdragRef.current;
      const appoContainer = APPOdragRef.current;
      const recepContainer = RECEPdragRef.current;
      const quotContainer = QUOTdragRef.current;
      const progContainer = PROGdragRef.current;
      const readyContainer = READYdragRef.current;
      const closContainer = CLOSdragRef.current;
      const dragulaRef = Dragula(
        [
          pendContainer,
          appoContainer,
          recepContainer,
          quotContainer,
          progContainer,
          readyContainer,
          closContainer,
        ],
        {},
      );

      dragulaRef.on("drag", (el: any) => {
        el?.classList.add("dragging"); // Apply dragging style
      });

      dragulaRef.on("dragend", (el: any) => {
        el?.classList.remove("dragging"); // Remove dragging style
      });

      dragulaRef.on(
        "drop",
        (el: HTMLElement, target: HTMLElement, source: HTMLElement) => {
          const targetId = Number(target.id);
          const sourceId = Number(source.id);
          const appointmentStatus = el.id.replace(/[0-9]/g, "").slice(0, -1);

          if (targetId - 1 !== sourceId) {
            CkMessage({
              type: "error",
              text: "¡No se puede mover la cita a este estado! Recuerda seguir la secuencia de estados para mover tu cita.",
            });
            dragulaRef.cancel(true);
            return;
          }

          setLoadingNewState(true);
          const appointmentId = el.id.replace(/\D/g, "");
          const appointmentTemp = findAppointment(
            appointmentId,
            appointmentStatus,
            orderedList,
          );
          dragulaRef.cancel(true);
          if (appointmentStatus !== "QUOT")
            actionList[appointmentStatus]?.func(appointmentTemp);
          else {
            const quotState = appointmentTemp?.quotationStatus;
            if (quotState === "APPROVED") {
              actionList["QUOT"]?.approved.func(appointmentTemp);
            } else {
              actionList["QUOT"]?.notApproved.func(appointmentTemp);
            }
          }
        },
      );

      // Clean up Dragula instance on component unmount
      return () => {
        dragulaRef.remove();
      };
    }
  }, [loadingNewState, orderedList]);

  return (
    <div
      className={["AppoGridView-container", ...(hidden ? ["hidden"] : [])].join(
        " ",
      )}
      ref={horizontalScrollRef}
      onScroll={(e) => {
        const el = e.target as HTMLElement;
        if (el.scrollLeft <= 0) setShowLeftArrow(false);
        else if (el.scrollLeft >= el.scrollWidth - el.clientWidth)
          setShowRightArrow(false);
        else {
          setShowLeftArrow(true);
          setShowRightArrow(true);
        }
      }}
    >
      <CkButton
        style={{
          display: showLeftArrow ? "" : "none",
        }}
        className="scroll left-arrow"
        onClick={() => {
          if (horizontalScrollRef.current)
            horizontalScrollRef.current.scrollLeft -= 150;
        }}
        icon="caret-left"
        shape="circle"
      />
      <CkButton
        style={{
          display: showRightArrow ? "" : "none",
        }}
        className="scroll right-arrow"
        onClick={() => {
          if (horizontalScrollRef.current)
            horizontalScrollRef.current.scrollLeft += 150;
        }}
        icon="caret-right"
        shape="circle"
      />
      <div className="section">
        <div className="title">Citas por empezar</div>
        <div className="appointments-container">
          <div className="column">
            <div
              className="column-title"
              style={{ borderBottom: "1px solid var(--PEND-color)" }}
            >
              {`Pendientes de confirmación (${orderedList[0]?.length})`}
            </div>
            <div ref={PENDdragRef} id="0" className="column-content">
              {orderedList[0] &&
                orderedList[0].map((event: any) => (
                  <ListMonthCard
                    key={event.publicId}
                    Info={event}
                    SimulateEventClick={SimulateEventClick}
                    actionClick={actionList[event.statusCode]?.func}
                    actionText={actionList[event.statusCode]?.label}
                    handleChangeDate={actionList["CANCE"]?.func}
                    handleCancelAppo={(data: any) => {
                      setCurrentAppointment(data);
                      setShowCancelAppointment(true);
                    }}
                    isLoading={loadingNewState}
                  />
                ))}
            </div>
          </div>
          <div className="column">
            <div
              className="column-title"
              style={{ borderBottom: "1px solid var(--APPO-color)" }}
            >
              {`Citas confirmadas (${orderedList[1]?.length})`}
            </div>
            <div ref={APPOdragRef} id="1" className="column-content">
              {orderedList[1] &&
                orderedList[1].map((event: any) => (
                  <ListMonthCard
                    key={event.publicId}
                    Info={event}
                    SimulateEventClick={SimulateEventClick}
                    actionClick={actionList[event.statusCode]?.func}
                    actionText={actionList[event.statusCode]?.label}
                    isLoading={loadingNewState}
                    handleCancelAppo={(data: any) => {
                      setCurrentAppointment(data);
                      setShowCancelAppointment(true);
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="title">Citas en progreso</div>
        <div className="appointments-container">
          <div className="column">
            <div
              className="column-title"
              style={{ borderBottom: "1px solid var(--RECEP-color)" }}
            >
              {`Recibidos (${orderedList[2]?.length})`}
            </div>
            <div ref={RECEPdragRef} id="2" className="column-content">
              {orderedList[2] &&
                orderedList[2].map((event: any) => (
                  <ListMonthCard
                    key={event.publicId}
                    Info={event}
                    SimulateEventClick={SimulateEventClick}
                    actionClick={actionList[event.statusCode]?.func}
                    actionText={actionList[event.statusCode]?.label}
                    isLoading={loadingNewState}
                  />
                ))}
            </div>
          </div>
          <div className="column">
            <div
              className="column-title"
              style={{ borderBottom: "1px solid var(--QUOT-color)" }}
            >
              {`Cotizados (${orderedList[3]?.length})`}
            </div>
            <div ref={QUOTdragRef} id="3" className="column-content">
              {orderedList[3] &&
                orderedList[3].map((event: any) => (
                  <ListMonthCard
                    key={event.publicId}
                    Info={event}
                    SimulateEventClick={SimulateEventClick}
                    quotationActions={actionList[event.statusCode]}
                    actionClick={actionList[event.statusCode]?.notApproved.func}
                    actionText={actionList[event.statusCode]?.notApproved.label}
                    isLoading={loadingNewState}
                  />
                ))}
            </div>
          </div>
          <div className="column">
            <div
              className="column-title"
              style={{ borderBottom: "1px solid var(--PROG-color)" }}
            >
              {`Reparando (${orderedList[4]?.length})`}
            </div>
            <div ref={PROGdragRef} id="4" className="column-content">
              {orderedList[4] &&
                orderedList[4].map((event: any) => (
                  <ListMonthCard
                    key={event.publicId}
                    Info={event}
                    SimulateEventClick={SimulateEventClick}
                    actionClick={actionList[event.statusCode]?.func}
                    actionText={actionList[event.statusCode]?.label}
                    isLoading={loadingNewState}
                  />
                ))}
            </div>
          </div>
          <div className="column">
            <div
              className="column-title"
              style={{ borderBottom: "1px solid var(--READY-color)" }}
            >
              {`Terminados (${orderedList[5]?.length})`}
            </div>
            <div ref={READYdragRef} id="5" className="column-content">
              {orderedList[5] &&
                orderedList[5].map((event: any) => (
                  <ListMonthCard
                    key={event.publicId}
                    Info={event}
                    SimulateEventClick={SimulateEventClick}
                    actionClick={actionList[event.statusCode]?.func}
                    actionText={actionList[event.statusCode]?.label}
                    isLoading={loadingNewState}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="title">Citas cerradas</div>
        <div className="appointments-container">
          <div className="column">
            <div
              className="column-title"
              style={{ borderBottom: "1px solid var(--CLOS-color)" }}
            >
              {`Entregados (${orderedList[6]?.length})`}
            </div>
            <div ref={CLOSdragRef} id="6" className="column-content">
              {orderedList[6] &&
                orderedList[6].map((event: any) => (
                  <ListMonthCard
                    key={event.publicId}
                    Info={event}
                    SimulateEventClick={SimulateEventClick}
                    actionClick={actionList[event.statusCode]?.func}
                    actionText={actionList[event.statusCode]?.label}
                    isLoading={loadingNewState}
                  />
                ))}
            </div>
          </div>
          <div className="column">
            <div
              className="column-title"
              style={{ borderBottom: "1px solid var(--CANCE-color)" }}
            >
              {`Canceladas (${orderedList[7]?.length})`}
            </div>
            {orderedList[7] &&
              orderedList[7].map((event: any) => (
                <ListMonthCard
                  key={event.publicId}
                  Info={event}
                  SimulateEventClick={SimulateEventClick}
                  actionClick={actionList[event.statusCode]?.func}
                  actionText={actionList[event.statusCode]?.label}
                  isLoading={loadingNewState}
                />
              ))}
          </div>
        </div>
      </div>

      {showNewAppointmentDate && (
        <RescheduleModal
          loadingReschedule={loadingReschedule}
          startDateTime={currentAppointment?.startDateTime}
          showNewAppointmentDate={showNewAppointmentDate}
          setShowNewAppointmentDate={setShowNewAppointmentDate}
          getClosedDays={getClosedDays}
          newAppointmentDate={newAppointmentDate}
          setNewAppointmentDate={setNewAppointmentDate}
          getMonthByNumber={getMonthByNumber}
          appointmentHourList={appointmentHourList}
          rescheduleAppointment={rescheduleAppointment}
          getWorkingHoursByDay={getWorkingHoursByDay}
          hours={hours}
        />
      )}

      {showReceptionForm && (
        <NEWReceptionFormModal
          receptionConfirmation={showReceptionForm}
          setReceptionConfirmation={setShowReceptionForm}
          appointmentInfo={currentAppointment}
          hours={hours}
          appointmentId={currentAppointment?.id}
          workshopId={workshopId}
          services={currentAppointment?.serviceTypes}
          packages={currentAppointment?.packages}
          handleConfirm={handleConfirmOld}
          coworkers={coworkers}
          statusAppointment={currentAppointment?.statusCode}
        />
      )}

      {showDiagnosticForm && (
        <DiagnosticFormModal
          diagnosticConfirmation={showDiagnosticForm}
          setDiagnosticConfirmation={setShowDiagnosticForm}
          appointmentInfo={currentAppointment}
          hours={hours}
          appointmentId={currentAppointment?.id}
          workshopId={workshopId}
          services={currentAppointment?.serviceTypes}
          packages={currentAppointment?.packages}
          handleConfirm={handleConfirmOld}
          coworkers={coworkers}
          mode={diagnosticFormMode}
          isCalledFromCalendar={true}
        />
      )}

      <ConfirmationModal
        visible={showCancelAppointment}
        onOk={() => {
          handleConfirmOld("CANCE");
          exitModal();
        }}
        onCancel={() => {
          setShowCancelAppointment(false);
        }}
        title="Cancelar cita"
        message="¿Estás seguro de que deseas cancelar la cita?"
        subMessage="Asegúrate de comunicarte con tu cliente para hacérselo saber."
        icon={<CalendarDeleteIcon style={{ width: "100px" }} />}
      />

      <ConfirmAppointmentModal
        showModal={showAppointmentApprovalModal}
        setShowModal={(show) => {
          setShowAppointmentApprovalModal(show);
          setLoadingNewState(false);
        }}
        updateStatus={confirmAppointment}
      />
    </div>
  );
};

export default memo(AppoGridView);
