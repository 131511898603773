import React, { useState, useEffect } from "react";

import { Popover, Checkbox, Button, Divider, Switch } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import {
  RightArrowIcon,
  LeftArrowIcon,
  ChevronIcon,
} from "../../../../assets/SvgIcons";

import RefreshIco from "../../../../assets/Images/refresh_03.svg";

import "./index.css";
import { useHistory } from "react-router-dom";
import { useIsMobileScreen } from "../../../Utilities";
import { CkLink, CkButton } from "../../../../CkUI";

interface IProps {
  refresh: boolean;
  showAllAppos: boolean;
  calendarView: string;
  showWeekends: boolean;
  setShowWeekends: Function;
  setCalendarView: Function;
  appointmentFilters: { [id: string]: boolean };
  calendarTitle: string;
  setFilter: Function;
  setRefresh: Function;
  setAppointmentFilters: Function;
  changeCalendarPage: Function;
  HandleChange: Function;
}

const appointmentStatus = [
  { code: "CREAT", desc: "Pendiente" },
  { code: "APPO", desc: "Cita confirmada" },
  { code: "RECEP", desc: "Recibido" },
  { code: "DIAG", desc: "Diagnosticado" },
  { code: "QUOT", desc: "Cotizado" },
  { code: "PROG", desc: "Reparando" },
  { code: "READY", desc: "Completado" },
  { code: "CLOS", desc: "Entregado" },
  { code: "CANCE", desc: "Cancelado" },
  { code: "PEND", desc: "Por confirmar" },
];

const filtersText: { [id: string]: string } = {
  CREAT: "Pendiente",
  PEND: "Por confirmar",
  APPO: "Cita confirmada",
  RECEP: "Recibido",
  DIAG: "Diagnosticado",
  QUOT: "Cotizado",
  PROG: "Reparando",
  READY: "Completado",
  CLOS: "Entregado",
  CANCE: "Cancelado",
};

const CalendarHeader: React.FC<IProps> = ({
  refresh,
  showAllAppos,
  calendarView,
  showWeekends,
  setShowWeekends,
  appointmentFilters,
  calendarTitle,
  setFilter,
  setRefresh,
  setAppointmentFilters,
  changeCalendarPage,
  HandleChange,
}) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [filterState, setFilterState] = useState<string>("Ver todas");
  const isMobile = useIsMobileScreen(993);

  useEffect(() => {
    let allTrue = true;
    let textString = "";
    for (const property in appointmentFilters) {
      if (!appointmentFilters[property]) {
        allTrue = false;
      }
      if (appointmentFilters[property]) {
        textString = textString + ", " + filtersText[property];
      }
    }
    if (allTrue) {
      setFilterState("Ver todas");
    } else {
      setFilterState(textString.slice(2));
    }
  }, [appointmentFilters]);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const reloadRender = (isWeb: boolean) => {
    return (
      <CkButton
        variant="text"
        onClick={() => {
          setRefresh(true);
        }}
        className={`div-row  ${isWeb ? "for-web" : "for-mob"}`}
        icon={"refresh"}
        loading={refresh}
        shape="circle"
        color="mariner"
      />
    );
  };

  const calendarFilter = (isWeb: boolean) => {
    return (
      <div className={`filters-container ${isWeb ? "for-web" : "for-mob"}`}>
        <div className="select-range">
          <div className="label">Vista:</div>
          <div className="view-options">
            <CkButton
              block={false}
              variant={calendarView === "listMonth" ? "primary" : "secondary" }
              size="small"
              onClick={() => HandleChange("listMonth")}
              className={
                calendarView === "listMonth"
                  ? "range-option option-selected"
                  : "range-option"
              }
            >
              Citas
            </CkButton>
            <CkButton
            block={false}
            variant={calendarView === "timeGridDay" ? "primary" : "secondary" }
            size="small"
              onClick={() => HandleChange("timeGridDay")}
              className={
                calendarView === "timeGridDay"
                  ? "range-option option-selected"
                  : "range-option"
              }
            >
              Día
            </CkButton>
            <CkButton
              block={false}
              variant={calendarView === "timeGridWeek" ? "primary" : "secondary" }
              size="small"
              onClick={() => HandleChange("timeGridWeek")}
              className={
                calendarView === "timeGridWeek"
                  ? "range-option option-selected"
                  : "range-option"
              }
            >
              Semana
            </CkButton>
            <CkButton
              block={false}
              variant={calendarView === "dayGridMonth" ? "primary" : "secondary" }
              size="small"
              onClick={() => HandleChange("dayGridMonth")}
              className={
                calendarView === "dayGridMonth"
                  ? "range-option option-selected"
                  : "range-option"
              }
            >
              Mes
            </CkButton>
          </div>
        </div>
      </div>
    );
  };

  const popoverRender = () => {
    return (
      <div className="popover-status-content-ag-ws">
        <div>
          <Checkbox
            checked={!showAllAppos}
            onClick={(e) => {
              const appoFilters = { ...appointmentFilters };
              for (const ref in appoFilters) {
                appoFilters[ref] = showAllAppos;
              }
              setAppointmentFilters(appoFilters);
              HandleChange(calendarView);
            }}
          >
            <p className="standar contents">Todos</p>
          </Checkbox>
        </div>
        {appointmentStatus.map((status, i: number) => (
          <div
            className={i + 1 == appointmentStatus.length ? "last" : ""}
            key={status.code}
          >
            <Checkbox
              className={`check-custom-${status.code}`}
              checked={appointmentFilters[status.code]}
              onChange={(e) => {
                setFilter(e, status.code);
              }}
            >
              <p
                className="standar contents"
                style={{ color: `var(--${status.code}-color)` }}
              >
                {status.desc}
              </p>
            </Checkbox>
          </div>
        ))}
      </div>
    );
  };

  return !isMobile ? (
    <div className="calendar-header">
      <div className="title-container">
        <div className="filter-mob for-mob">
          {reloadRender(false)}
          {calendarFilter(false)}
        </div>
        <div className="title">
          <h3>{calendarTitle}</h3>
          <div className="buttons">
            <CkButton
              variant="secondary"
              color="mariner"
              onClick={() => changeCalendarPage("prev")}
              shape="round"
              icon="arrow-left"
              className="prev-button"
            />
            <CkButton
              variant="secondary"
              color="mariner" 
              onClick={() => changeCalendarPage("next")}
              shape="round"
              icon="arrow-right"
              className="next-button"
            />
          </div>
        </div>
        <CkLink
          to="/agregar-cita"
        >
          + Agregar una cita
        </CkLink>
      </div>
      <Divider className="divider" />
      <div className="buttons-option">
        <div className="left-options">
          {calendarFilter(true)}
          {calendarView !== "listMonth" && calendarView !== "timeGridDay" && (
            <div className="switch-container">
              <Switch checked={showWeekends} onChange={setShowWeekends} />
              <div className="label">Ver fines de semana</div>
            </div>
          )}
        </div>
        <div className="right-options">
          <div className="type-select-reload">
            {calendarView !== "listMonth" && (
              <>
                <div className="label">Filtrar por tipo de cita:</div>
                <Popover
                  content={popoverRender}
                  trigger="click"
                  open={open}
                  onOpenChange={handleOpenChange}
                  showArrow={false}
                  style={{ padding: "0px" }}
                  overlayStyle={{
                    padding: "0px",
                  }}
                  overlayInnerStyle={{
                    padding: "0px",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  <Button
                    className={[
                      "popover-button",
                      open ? "is-open-hideborder" : "",
                    ].join(" ")}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "100%",
                        padding: "0px 0px 0px 9px",
                      }}
                    >
                      <span
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {filterState}
                      </span>
                      <ChevronIcon />
                    </div>
                  </Button>
                </Popover>
              </>
            )}
            {reloadRender(true)}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="calendar-header">
      <div className="title-container">
        <div className="title">
          <div className="buttons">
            <Button
              onClick={() => changeCalendarPage("prev")}
              shape="circle"
              icon={<LeftArrowIcon />}
              className="prev-button"
            />
            <div
              style={{
                color: "#475569",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              {calendarView === "timeGridWeek"
                ? calendarTitle.slice(10)
                : calendarTitle}
            </div>
            <Button
              onClick={() => changeCalendarPage("next")}
              shape="circle"
              icon={<RightArrowIcon />}
              className="next-button"
            />
          </div>
        </div>
        <CkLink
          to="/agregar-cita"
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          + Agregar una cita
        </CkLink>
        <Divider className="divider" />
        <div className="filter-mob for-mob">{calendarFilter(false)}</div>
      </div>
      <div className="right-options">
        <div className="type-select-reload">
          <div className="label">Filtrar por tipo de cita:</div>
          <Popover
            content={popoverRender}
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
            showArrow={false}
            style={{ padding: "0px" }}
            overlayStyle={{
              padding: "0px",
            }}
            overlayInnerStyle={{
              padding: "0px",
              boxShadow: "none",
              backgroundColor: "transparent",
            }}
          >
            <Button
              className={[
                "popover-button",
                open ? "is-open-hideborder" : "",
              ].join(" ")}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                  padding: "0px 0px 0px 11px",
                }}
              >
                <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  {filterState}
                </span>
                <ChevronIcon />
              </div>
            </Button>
          </Popover>
          {reloadRender(false)}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CalendarHeader);
