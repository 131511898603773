import React, { FC, memo } from "react";
import "./styles.css";
import { WorkshopService } from "shared";
import { tierCodeToDesc } from "../../../Utilities";

interface IProps {
  Info: any;
}

const TimeGridDay: FC<IProps> = ({ Info }) => {
  const data = Info ? Info?.event?._def?.extendedProps : null;
  const isServices =
    data.serviceTypes.length > 0 || data.packages.length > 0
      ? data.serviceTypes.length > 0
        ? true
        : false
      : undefined;
  const requestedService: WorkshopService | undefined =
    data && Array.isArray(data.customServices) && data.customServices.length > 0
      ? data.customServices[0]
      : undefined;

  const getServiceString = (flag: boolean) => {
    if (flag) {
      return data.serviceTypes[0].serviceTypeName;
    } else {
      let packStr: string = "";
      data.packages.map((pack: any, index: number) => {
        if (index == 0) {
          packStr = `${pack.servicePackageDesc}`;
        } else {
          packStr = `${packStr}, ${pack.servicePackageDesc}`;
        }
      });
      return packStr;
    }
  };

  return (
    <div className={`timeGridDay-container`}>
      <div className="appo-status">
        <div
          className={["status-dot", `--background-${data.statusCode}`].join(
            " "
          )}
        />
        <p>{data && data.statusName}</p>
      </div>
      <div className="appo-info">
        <p className="vehicle-info">
          {data && data.vehicle.brandName} - {data && data.vehicle.modelName}
        </p>
        <p>
          {requestedService === undefined &&
            isServices !== undefined &&
            getServiceString(isServices)}
        </p>
        {requestedService && requestedService.workshopServiceType === "MAN" && (
          <p>
            {requestedService.workshopServiceName} -{" "}
            {tierCodeToDesc(
              requestedService?.serviceTiers[0]?.servicePackageTypeCode || ""
            )}
          </p>
        )}
        {requestedService && requestedService.workshopServiceType === "REP" && (
          <p>{requestedService.workshopServiceName}</p>
        )}
      </div>
    </div>
  );
};

export default memo(TimeGridDay);
