import React, { useState, useEffect, memo } from "react";
import { Button, Popover } from "antd";
import moment from "moment";
import "./index.css";
import KebabMenu from "../../../../assets/Images/MenuIcons/KebabMenu";
import StopIcon from "../../../../assets/Images/MenuIcons/StopIcon";
import EditPencilIcon from "../../../../assets/Images/MenuIcons/EditPencilIcon";
import { CkButton, CkIcon } from "../../../../CkUI";
import { WorkshopService } from "shared";
import { tierCodeToDesc } from "../../../Utilities";
interface IProps {
  Info: any;
  SimulateEventClick: Function;
  actionClick?: Function;
  actionText?: string;
  quotationActions?: any;
  isLoading: boolean;
  handleChangeDate?: Function;
  handleCancelAppo?: Function;
}

const ListMonthCard: React.FC<IProps> = ({
  Info: data,
  SimulateEventClick,
  actionClick,
  actionText,
  quotationActions,
  isLoading,
  handleChangeDate,
  handleCancelAppo,
}) => {
  const [isQuotApproved, setIsQuotApproved] = useState<boolean>(false);
  const [requestedService, setRequestedService] = useState<WorkshopService>();

  const isServices =
    data?.serviceTypes?.length > 0 || data?.packages?.length > 0
      ? data.serviceTypes.length > 0
        ? true
        : false
      : undefined;

  useEffect(() => {
    if (data && Array.isArray(data.customServices) && data.customServices.length > 0) {
      setRequestedService(data.customServices[0]);
    }
    if (data && data.statusCode === "QUOT") {
      setIsQuotApproved(data?.quotationStatus === "APPROVED");
    }
  }, [data]);

  const getButtonAction = () => {
    if (data?.statusCode !== "QUOT") return actionClick;
    if (isQuotApproved) return quotationActions.approved.func;
    return quotationActions.notApproved.func;
  };

  const getButtonText = () => {
    if (data?.statusCode !== "QUOT") return actionText;
    if (isQuotApproved) return quotationActions.approved.label;
    return quotationActions.notApproved.label;
  };

  const getServiceString = (flag: boolean) => {
    if (flag) {
      let servStr: string = "";
      data.serviceTypes.map((serv: any, index: number) => {
        if (index == 0) {
          servStr = serv.serviceTypeName;
        } else {
          servStr = `${servStr}, ${serv.serviceTypeName} `;
        }
      });

      return servStr;
    } else {
      let packStr: string = "";
      data.packages.map((pack: any, index: number) => {
        if (index == 0) {
          packStr = `${pack.servicePackageDesc} - ${pack.servicePackageTypeDesc}`;
        } else {
          packStr = `${packStr}, ${pack.servicePackageDesc} - ${pack.servicePackageTypeDesc}`;
        }
      });
      return packStr;
    }
  };

  const capitalizeString = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  if (!!data === false) return null;

  const PopoverRender = () => (
    <div className="card-actions-popover">
      {["CREAT", "PEND"].includes(data?.statusCode) && (
        <div
          className="popover-label"
          style={{ color: "var(--primary-color)" }}
          onClick={() => {
            if (handleChangeDate) handleChangeDate(data);
          }}
        >
          <EditPencilIcon />
          Cambiar
        </div>
      )}
      {["CREAT", "PEND"].includes(data?.statusCode) && (
        <div
          className="popover-label"
          onClick={() => {
            if (handleCancelAppo) handleCancelAppo(data);
          }}
          style={{ color: "#F00" }}
        >
          <StopIcon style={{ width: "23px", height: "23px" }} />
          Cancelar
        </div>
      )}
    </div>
  );

  return (
    <div
      id={`${data?.statusCode}-${data?.id}`}
      className={`listMonth-container`}
    >
      <div className="card-container">
        <div className="card-head-container">
          <div className="card-head">
            <div
              className={["status-dot", `--background-${data.statusCode}`].join(
                " ",
              )}
            />
            <div className="appo-status">{data.statusName}</div>
          </div>

          {["CREAT", "PEND"].includes(data?.statusCode) && (
            <Popover
              content={PopoverRender()}
              trigger="hover"
              overlayStyle={{ width: "175px" }}
              placement="bottom"
            >
              <KebabMenu
                style={{
                  color: "var(--primary-color)",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              />
            </Popover>
          )}
        </div>
        <div className="card-body">
          <div>
            <div className="appo-date">
              {`${capitalizeString(
                moment(data.startDateTime).format("dddd"),
              )} ${moment(data.startDateTime).format("LL")}`}
            </div>
            <div className="appo-date">
              {moment(data.startDateTime).format("h:mm a")}
            </div>
          </div>
          <div className="appo-label">
            Vehiculo:{" "}
            {data.vehicle.nickname
              ? data.vehicle.nickname
              : `${data.vehicle.brandName} - ${data.vehicle.modelName}`}
          </div>
          <div className="appo-label">
            {isServices
              ? "Servicio(s): "
              : data.packages.length > 0
                ? "Paquete: "
                : ""}
            {isServices !== undefined && getServiceString(isServices)}
            {requestedService && requestedService.workshopServiceType === "MAN" && `Mantenimiento: ${requestedService.workshopServiceName} - ${tierCodeToDesc(requestedService?.serviceTiers[0].servicePackageTypeCode || "")}`}
            {requestedService && requestedService.workshopServiceType === "REP" && `Reparación: ${requestedService.workshopServiceName}`}
          </div>
          <div
            className="appo-link"
            onClick={() => SimulateEventClick && SimulateEventClick(data)}
          >
            Ver detalles
          </div>
          {(data.endConsumer.name || data.endConsumer.lastName) && (
            <div className="appo-label" style={{ fontWeight: 600 }}>
              {[
                ...(data.endConsumer.name ? [data.endConsumer.name] : [""]),
                ...(data.endConsumer.lastName
                  ? [data.endConsumer.lastName]
                  : [""]),
              ].join(" ")}
            </div>
          )}
          <div className="contact-info">
            <a
              className="contact-label"
              target="_blank"
              href={`tel:${data.endConsumer.phone}`}
            >
              <CkIcon name="phone" />
              {data.endConsumer.phone}
            </a>
          </div>
          <div className="contact-info">
            <a
              className="contact-label"
              target="_blank"
              href={`mailto:${data.endConsumer.email}`}
            >
              <CkIcon name="envelop" />
              {data.endConsumer.email}
            </a>
          </div>
        </div>
        {actionClick && actionText && (
          <div className="card-actions">
            <Button
              className="card-button"
              onClick={() => getButtonAction()(data)}
              disabled={isLoading}
            >
              {getButtonText()}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ListMonthCard);
