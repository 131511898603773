import React, { FC, memo } from "react";
import "./styles.css";
import { WorkshopService } from "shared";
import { tierCodeToDesc } from "../../../Utilities";

interface IProps {
  Info: any;
}

const TimeGridWeekCard: FC<IProps> = ({ Info }) => {
  const data = Info ? Info?.event?._def?.extendedProps : null;
  const requestedService: WorkshopService | undefined =
    data && Array.isArray(data.customServices) && data.customServices.length > 0
      ? data.customServices[0]
      : undefined;

  return (
    <div className="time-grid-week-card">
      <div className="header">
        <div
          className={["status-dot", `--background-${data.statusCode}`].join(
            " ",
          )}
        />
        {data.statusName}
      </div>
      <div className="body">
        <div>
          {data.vehicle.brandName} {data.vehicle.modelName} {data.vehicle.year}
        </div>
        {requestedService === undefined && data?.packages?.length > 0 && (
          <div>{data.packages[0].servicePackageDesc}</div>
        )}
        {requestedService === undefined && data?.serviceTypes?.length > 0 && (
          <div>{data.serviceTypes[0].serviceTypeName}</div>
        )}
        {requestedService &&
            requestedService.workshopServiceType === "MAN" && (
              <div>
                {requestedService.workshopServiceName} -{" "}
                {tierCodeToDesc(
                  requestedService?.serviceTiers[0]?.servicePackageTypeCode ||
                    ""
                )}
              </div>
            )}
          {requestedService &&
            requestedService.workshopServiceType === "REP" && (
              <div>
                {requestedService.workshopServiceName}
              </div>
            )}
      </div>
    </div>
  );
};
{
  /* <div className="time-grid-week-card"> */
}
{
  /*   <p className={`--text-${(data && data.statusCode) || ""}`}> */
}
{
  /*     {`${data && data.vehicle.brandName} - ${ */
}
{
  /*       data && data.vehicle.modelName */
}
{
  /*     }` || */
}
{
  /*       `${data && data.vehicle.nickname}` || */
}
{
  /*       ""} */
}
{
  /*   </p> */
}
{
  /* </div> */
}

export default memo(TimeGridWeekCard);
