import React, { memo } from "react";
import { Popover } from "antd";
import { useHistory } from "react-router-dom";
import "./index.css";
import { WorkshopService } from "shared";
import { tierCodeToDesc } from "../../../Utilities";

interface IProps {
  Info: any;
}

const DayGridMonthCard: React.FC<IProps> = ({ Info }) => {
  const history = useHistory();
  const data = Info ? Info?.event?._def?.extendedProps : null;
  const appointmentId = Info ? Info?.event?._def?.publicId : null;
  const requestedService: WorkshopService | undefined =
    data && Array.isArray(data.customServices) && data.customServices.length > 0
      ? data.customServices[0]
      : undefined;

  const popoverContent = () => {
    return (
      <div
        className="calendar-popover-container"
        onClick={() => history.push(`/cita/${appointmentId}`)}
      >
        <div className="header">
          <div>
            {new Date(data?.startDateTime).toLocaleString("es-Mx", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
          </div>
        </div>
        <div className="body">
          <div className="status-info">
            <div
              className={["status-dot", `--background-${data.statusCode}`].join(
                " "
              )}
            />
            <div>{data.statusName}</div>
          </div>
          <div className="time-info">
            {new Date(data?.startDateTime)
              .toLocaleTimeString("es-mx", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
              .toUpperCase()}
          </div>
          <div className="vehicle-info">
            {`${data.vehicle.brandName.toLowerCase()} ${data.vehicle.modelName.toLowerCase()}`}
          </div>
          {requestedService === undefined && data.packages.length > 0 && (
            <div className="problem-info">
              {data.packages[0]?.servicePackageDesc}
            </div>
          )}
          {requestedService === undefined && data.serviceTypes.length > 0 && (
            <div className="problem-info">
              {data.serviceTypes[0]?.serviceTypeName}
            </div>
          )}
          {requestedService &&
            requestedService.workshopServiceType === "MAN" && (
              <div className="problem-info">
                {requestedService.workshopServiceName} -{" "}
                {tierCodeToDesc(
                  requestedService?.serviceTiers[0]?.servicePackageTypeCode ||
                    ""
                )}
              </div>
            )}
          {requestedService &&
            requestedService.workshopServiceType === "REP" && (
              <div className="problem-info">
                {requestedService.workshopServiceName}
              </div>
            )}
        </div>
      </div>
    );
  };

  return (
    data && (
      <Popover
        placement="top"
        content={popoverContent()}
        trigger="hover"
        // open={true}
      >
        <div className="dayGridMonth-container">
          <div className="header">
            <div
              className={["status-dot", `--background-${data.statusCode}`].join(
                " "
              )}
            />
            <div>{data.statusName}</div>
          </div>
          {/* <div> */}
          {/*   <div>{data.vehicle.brandName}</div> */}
          {/*   <div>{data.packages[0]?.servicePackageDesc}</div> */}
          {/* </div> */}
        </div>
      </Popover>
    )
  );
};

export default memo(DayGridMonthCard);
