import React, { FC, memo, useEffect, useState } from "react";
import moment from "moment";
import "./styles.css";
import { WorkshopService } from "shared";
import { tierCodeToDesc } from "../../../Utilities";

interface IProps {
  events: any;
  calendarRange: any;
  handleClick: Function;
  hidden: boolean;
  isMobile: boolean;
}

const defaultDays = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

const MobileWeekList: FC<IProps> = ({
  events,
  calendarRange,
  handleClick,
  hidden,
  isMobile,
}) => {
  const [days, setDays] = useState(defaultDays);
  const [eventList, setEventList] = useState([[], [], [], [], [], [], []]);

  const setDaysList = () => {
    if (calendarRange.startDay === null) return;
    let currentDay = calendarRange.startDay;
    const daysList = [...defaultDays];
    for (let i = 0; i < 7; i++) {
      daysList[i] = daysList[i] + " " + moment(currentDay).format("DD");
      currentDay = moment(currentDay).add(1, "days");
    }
    setDays(daysList);
  };

  const buildEventList = () => {
    let eventList: any = [[], [], [], [], [], [], []];
    events.forEach((event: any) => {
      const tempMoment = moment(event.startDateTime);
      const day = tempMoment.day(); // 0 -> Sunday, 6 -> Saturday
      if (day === 0) eventList[6].push(event);
      if (day === 1) eventList[0].push(event);
      if (day === 2) eventList[1].push(event);
      if (day === 3) eventList[2].push(event);
      if (day === 4) eventList[3].push(event);
      if (day === 5) eventList[4].push(event);
      if (day === 6) eventList[5].push(event);
    });
    setEventList(eventList);
  };

  useEffect(() => {
    setDaysList();
    buildEventList();
  }, [events, calendarRange]);

  const eventCard = (index: number, event: any) => {
    const requestedService: WorkshopService | undefined =
      event &&
      Array.isArray(event.customServices) &&
      event.customServices.length > 0
        ? event.customServices[0]
        : undefined;

    return (
      <div key={index} className="event-container">
        <div className="time">
          {moment(event.startDateTime).format("hh:mm A")}
        </div>
        <div className="card-container" onClick={() => handleClick(event)}>
          <div className="header">
            <div
              className={[
                "status-dot",
                `--background-${event.statusCode}`,
              ].join(" ")}
            />
            {event.statusName}
          </div>
          <div className="body">
            <div>
              {event.vehicle.brandName} {event.vehicle.modelName}{" "}
              {event.vehicle.year}
            </div>
            {event?.packages?.length > 0 && (
              <div>{event.packages[0].servicePackageDesc}</div>
            )}
            {event?.serviceTypes?.length > 0 && (
              <div>{event.serviceTypes[0].serviceTypeName}</div>
            )}
            {requestedService &&
              requestedService.workshopServiceType === "MAN" && (
                <div>
                  {requestedService.workshopServiceName} -{" "}
                  {tierCodeToDesc(
                    requestedService?.serviceTiers[0]?.servicePackageTypeCode ||
                      ""
                  )}
                </div>
              )}
            {requestedService &&
              requestedService.workshopServiceType === "REP" && (
                <div>{requestedService.workshopServiceName}</div>
              )}
          </div>
        </div>
      </div>
    );
  };

  return (
    calendarRange &&
    events.length > 0 &&
    !hidden &&
    isMobile && (
      <div className="mobile-week-list">
        <div className="date-title">{days[0]}</div>
        <div className="day-container">
          {eventList[0].map((event: any, index: number) =>
            eventCard(index, event)
          )}
        </div>
        <div className="date-title">{days[1]}</div>
        <div className="day-container">
          {eventList[1].map((event: any, index: number) =>
            eventCard(index, event)
          )}
        </div>
        <div className="date-title">{days[2]}</div>
        <div className="day-container">
          {eventList[2].map((event: any, index: number) =>
            eventCard(index, event)
          )}
        </div>
        <div className="date-title">{days[3]}</div>
        <div className="day-container">
          {eventList[3].map((event: any, index: number) =>
            eventCard(index, event)
          )}
        </div>
        <div className="date-title">{days[4]}</div>
        <div className="day-container">
          {eventList[4].map((event: any, index: number) =>
            eventCard(index, event)
          )}
        </div>
        <div className="date-title">{days[5]}</div>
        <div className="day-container">
          {eventList[5].map((event: any, index: number) =>
            eventCard(index, event)
          )}
        </div>
        <div className="date-title">{days[6]}</div>
        <div className="day-container">
          {eventList[6].map((event: any, index: number) =>
            eventCard(index, event)
          )}
        </div>
      </div>
    )
  );
};

export default memo(MobileWeekList);
